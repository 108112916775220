import { template as template_de86c78d09af4460a663cff6f36d9e89 } from "@ember/template-compiler";
import { Input } from "@ember/component";
import concatClass from "discourse/helpers/concat-class";
import { i18n } from "discourse-i18n";
const PreferenceCheckbox = template_de86c78d09af4460a663cff6f36d9e89(`
  <div class={{concatClass "controls" @class}} ...attributes>
    <label class="checkbox-label">
      <Input @type="checkbox" @checked={{@checked}} disabled={{@disabled}} />

      {{#if @labelCount}}
        {{i18n @labelKey count=@labelCount}}
      {{else}}
        {{i18n @labelKey}}
      {{/if}}
    </label>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PreferenceCheckbox;
