import { template as template_ab55a0989710463fa0da155156ff7915 } from "@ember/template-compiler";
const WelcomeHeader = template_ab55a0989710463fa0da155156ff7915(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
