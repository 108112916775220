import { template as template_2b4de2a8e1804d58af3e9da8b46a9663 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_2b4de2a8e1804d58af3e9da8b46a9663(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
