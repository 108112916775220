import { template as template_1b7c31b4e6064bd2a7e8b6c9fba74de7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_1b7c31b4e6064bd2a7e8b6c9fba74de7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
